import { getAccessToken } from 'authProvider'
import axios from 'axios'
import { isNil } from 'lodash'
import AxiosStorage from 'axios-storage'
import { PF_ENDPOINT_URL } from 'constants/globals'

AxiosStorage.config({
  storagePrefix: 'pf-admin',
  storageMode: 'sessionStorage',
  maxAge: 3 * 60 * 1000, // 30min
})

const http = axios.create({
  baseURL: PF_ENDPOINT_URL,
  adapter: AxiosStorage.adapter,
})

export const parseJwt = (token) => {
  if (!token?.split('.')[1]) return {}
  return JSON.parse(atob(token?.split('.')[1]))
}

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.data?.Message) {
      if (error?.response?.data?.Errors) {
        throw new Error(error?.response?.data?.Errors?.map((err) => err.message)?.join('; '))
      } else {
        throw new Error(error?.response?.data?.Message)
      }
    }
    throw error
  }
)

const http_no_store = axios.create({
  baseURL: PF_ENDPOINT_URL,
})

const getToken = async () => {
  try {
    const { accessToken } = await getAccessToken()
    return accessToken
  } catch (err) {
    console.log(err)
  }
}

export const isSuperUser = async () => {
  const accessToken = await getToken()
  return parseJwt(accessToken)?.groups?.includes('12108737-76b9-4767-8ff1-3250c7e20267')
}

export const getUploadHeader = async () => {
  const accessToken = await getToken()
  return {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  }
}

const base64Encode = (str) => {
  try {
    return btoa(unescape(encodeURIComponent(str)))
  } catch (e) {
    console.error(str, e)
  }
}

const queryParamsSerializer = function (params) {
  const output = {}
  Object.keys(params)?.forEach((key) => {
    if (!isNil(params[key])) {
      output[key] = base64Encode(params[key])
    }
  })
  return output
}
const postParamsSerializer = function (params) {
  if (!params) return params
  const output = JSON.stringify(params)

  return base64Encode(output)
}

const getWithToken = async (url, params = {}, config = { cache: true }) => {
  try {
    const accessToken = await getToken()
    return http.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/plain',
      },
      params: queryParamsSerializer(params),
      cacheConfig: config.cache,
    })
  } catch (err) {
    console.log(err)
  }
}

const getWithTokenNoStore = async (url, params = {}, config = { cache: true }) => {
  try {
    const accessToken = await getToken()
    return http_no_store.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/plain',
      },
      params: queryParamsSerializer(params),
      cacheConfig: config.cache,
    })
  } catch (err) {
    console.log(err)
  }
}

const putWithToken = async (url, data = {}, config = {}) => {
  const accessToken = await getToken()
  return http.put(url, postParamsSerializer(data), {
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'text/plain',
    },
  })
}

const putUploadWithToken = async (url, data = {}) => {
  const accessToken = await getToken()
  return http.put(url, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
  })
}

const postWithToken = async (url, data = {}) => {
  const accessToken = await getToken()
  return http.post(url, postParamsSerializer(data), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'text/plain',
    },
  })
}

const deleteWithToken = async (url, data = {}) => {
  const accessToken = await getToken()
  return http.delete(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'text/plain',
    },
    data: postParamsSerializer(data),
  })
}

export const adminApi = {
  /**
   * Brands
   */
  getBrands: (params = {}) => {
    return getWithToken('/brands', params, { cache: false })
  },
  getBrandById: (id, params = {}) => {
    return getWithToken(`/brands/${id}`, params, { cache: false })
  },
  postBrand: (params = {}) => {
    return postWithToken('/brands', params)
  },
  putBrandById: (id, params = {}) => {
    return putWithToken(`/brands/${id}`, params)
  },
  deleteBrandById: (id) => {
    return deleteWithToken(`/brands/${id}`)
  },
  /**
   * FeatureFlags
   */
  getFeatureFlags: async (params = {}) => {
    return getWithToken('/featureFlag', params, { cache: false })
  },
  putFeatureFlagById: async (params = {}) => {
    return putWithToken(`/featureFlag`, params, { params })
  },
  /**
   * Regions
   */
  getRegions: async (params = {}) => {
    return getWithToken('/regions', params, { cache: false })
  },
  getRegionById: async (id) => {
    return getWithToken(`/regions/${id}`)
  },
  postRegion: async (params = {}) => {
    return postWithToken('/regions', params)
  },
  putRegionById: async (id, params = {}) => {
    return putWithToken(`/regions/${id}`, params)
  },
  deleteRegionById: async (id) => {
    return deleteWithToken(`/regions/${id}`)
  },

  /**
   * Sponsors
   */
  getSponsors: async (params = {}) => {
    return getWithToken('/sponsors', params, { cache: false })
  },
  postSponsor: async (params = {}) => {
    return postWithToken('/sponsors/', params)
  },
  postSponsorsSubscriptionOverride: async ({ sponsorId } = {}) => {
    return postWithToken(`/sponsors/${sponsorId}/subscription-override`, {})
  },
  getSponsorById: async (id, params = {}) => {
    return getWithToken(`/sponsors/${id}`, params, { cache: false })
  },
  getEligibilites: async (params = {}) => {
    return getWithToken(`/eligibilities`, params, { cache: false })
  },
  postUserFromEligibility: async (id) => {
    return postWithToken(`/eligibilities/${id}/user`)
  },
  postOfflineUserFromEligibility: async (userInfo) => {
    return postWithToken(`/eligibilities/createfitonhealthuser`, userInfo)
  },
  putSponsorById: async (id, params) => {
    return putWithToken(`/sponsors/${id}`, params)
  },
  deleteSponsorById: async (id) => {
    return deleteWithToken(`/sponsors/${id}`)
  },
  getSponsorSubsidyTiers: async (sponsor_id, params = {}) => {
    return getWithToken(`/sponsors/subsidytiers/${sponsor_id}`, params, { cache: false })
  },
  putSponsorSubsidyTierById: async (tier_id, params = {}) => {
    return putWithToken(`/sponsors/subsidytiers/tiers/${tier_id}`, params)
  },
  postSponsorSubsidyTier: async (sponsor_id, params = {}) => {
    return postWithToken(`/sponsors/${sponsor_id}/subsidytiers`, params)
  },
  putUploadSponsorImage: async (id, data = {}) => {
    return putUploadWithToken(`/sponsors/${id}/images`, data)
  },
  deleteSponsorLogoImage: async (id) => {
    return deleteWithToken(`/sponsors/${id}/logo_image`)
  },
  postSponsorChallenge: async ({ sponsor_id, challenge_id }) => {
    return postWithToken(`/sponsors/${sponsor_id}/challenges/${challenge_id}`)
  },
  postSponsorReportDistributionList: async ({ sponsor_id, distribution_list }) => {
    return postWithToken(`/sponsors/${sponsor_id}/distribution-list`, { sponsor_id, distribution_list })
  },
  getSponsorReportDistributionList: async ({ sponsor_id, ...params }) => {
    return getWithToken(`/sponsors/${sponsor_id}/distribution-list`, params, { cache: false })
  },
  addSponsorAdmin: async ({ email, sponsor_id }) => {
    return postWithToken(`/sponsors/invite`, { email, sponsor_id })
  },
  getSponsorAdminList: async ({ sponsor_id }) => {
    return getWithToken(`/sponsors/${sponsor_id}/company-admin-list`, undefined, { cache: false })
  },
  deleteSponsorAdmin: async ({ sponsor_id }, { emails }) => {
    return deleteWithToken(`/sponsors/${sponsor_id}/company-admin`, { emails })
  },
  getStudioGroupContactList: async ({ sponsor_group_id }) => {
    return getWithToken(`/studiogroups/${sponsor_group_id}/contacts`, undefined, { cache: false })
  },
  addStudioGroupContact: async ({ email, sponsor_group_id, roles }) => {
    return postWithToken(`/studiogroups/${sponsor_group_id}/contacts`, { email, roles, sponsor_group_id })
  },
  editStudioGroupContact: async ({ email, sponsor_group_id, roles, id }) => {
    return putWithToken(`/studiogroups/${sponsor_group_id}/contacts/${id}`, { email, roles })
  },
  deleteStudioGroupContact: async ({ sponsor_group_id, id }) => {
    return deleteWithToken(`/studiogroups/${sponsor_group_id}/contacts/${id}`)
  },
  getReports: async () => {
    return getWithToken(`/reports/reports`)
  },
  getSponsorReports: async ({ sponsorId }) => {
    return getWithToken(`/sponsors/${sponsorId}/reports`, undefined, { cache: false })
  },
  triggerReport: async ({ sponsor_id, report_id }) => {
    return postWithToken(`/reports/trigger-metabase-run`, { sponsor_id, report_id })
  },

  /**
   * Studios
   */
  getStudios: async (params = {}) => {
    return getWithToken('/studios', params, { cache: false })
  },
  postStudio: async (params = {}) => {
    return postWithToken('/studios', params)
  },
  getStudioClassSessions: async (params = {}) => {
    return getWithToken('/studios/sessions/search', params)
  },
  getStudioById: async (id, params = {}) => {
    params.is_hidden = false
    return getWithToken(`/studios/${id}`, params, { cache: false })
  },
  validateStudio: async (params = {}) => {
    return postWithToken(`/studios/validate`, params, { cache: false })
  },
  putStudioById: async (id, data = {}) => {
    return putWithToken(`/studios/${id}`, data)
  },
  putUploadStudioImage: async (id, data = {}) => {
    return putUploadWithToken(`/studios/${id}/images`, data)
  },
  deleteStudioLogoImage: async (id) => {
    return deleteWithToken(`/studios/${id}/logo_image`)
  },
  deleteStudioBannerImage: async (id) => {
    return deleteWithToken(`/studios/${id}/banner_image`)
  },
  putStudioAddress: async (studio_id, data) => {
    return putWithToken(`/studios/${studio_id}/address`, data)
  },
  upsertPartner: async (params = {}) => {
    return postWithToken(`/studios/upsert-partner`, params)
  },

  /**
   * Studio Memberships
   */
  getStudioMemberships: async (studio_id, params = {}) => {
    return getWithToken(`/studios/${studio_id}/memberships`, params, { cache: false })
  },
  postStudioMembership: async (studio_id, params = {}) => {
    params.is_hidden = false
    return postWithToken(`/studios/${studio_id}/memberships`, params)
  },
  putStudioMembership: async ({ studio_id, plan_id, params = {} }) => {
    return putWithToken(`/studios/${studio_id}/memberships/${plan_id}`, params)
  },
  deleteStudioMembership: async ({ studio_id, plan_id }) => {
    return deleteWithToken(`/studios/${studio_id}/memberships/${plan_id}`)
  },

  /**
   * Studio Contacts
   */
  getStudioContactUrls: async (studio_id, params = {}) => {
    return getWithToken(`/studios/${studio_id}/contact-urls`, params, { cache: false })
  },
  putStudioContactUrls: async ({ studio_id, params }) => {
    return putWithToken(`/studios/${studio_id}/contact-urls`, params)
  },
  getStudioContacts: async (studio_id, params = {}) => {
    return getWithToken(`/studios/${studio_id}/studio-contacts`, params, { cache: false })
  },
  postStudioContact: async (studio_id, params = {}) => {
    return postWithToken(`/studios/${studio_id}/studio-contacts`, params)
  },
  putStudioContact: async ({ studio_id, contact_id, params = {} }) => {
    return putWithToken(`/studios/${studio_id}/studio-contacts/${contact_id}`, params)
  },
  deleteStudioContact: ({ studio_id, contact_id }) => {
    return deleteWithToken(`/studios/${studio_id}/studio-contacts/${contact_id}`)
  },

  /**
   * Studio Classes
   */
  getStudioClasses: async (studio_id, params = {}) => {
    return getWithToken(`/studios/${studio_id}/studio-classes`, params, { cache: false })
  },
  postStudioClasses: async (studio_id, params = {}) => {
    return postWithToken(`/studios/${studio_id}/studio-classes`, params)
  },
  putStudioClass: async (studio_id, class_id, params = {}) => {
    return putWithToken(`/studios/${studio_id}/studio-classes/${class_id}`, params)
  },
  deleteStudioClass: async ({ studio_id, class_id }) => {
    return deleteWithToken(`/studios/${studio_id}/studio-classes/${class_id}`)
  },

  /**
   * Studio Events
   */
  getStudioEvents: async (studio_id, params = {}) => {
    return getWithToken(`/studios/${studio_id}/studio-events`, params, { cache: false })
  },
  postStudioEvent: async (studio_id, params = {}) => {
    return postWithToken(`/studios/${studio_id}/events`, params)
  },
  putStudioEvent: async ({ studio_id, event_id, params = {} }) => {
    return putWithToken(`/studios/${studio_id}/events/${event_id}`, params)
  },
  deleteStudioEvent: ({ studio_id, event_id }) => {
    return deleteWithToken(`/studios/${studio_id}/events/${event_id}`)
  },

  /**
   * Studio Groups
   */
  getStudioGroups: async (params = {}) => {
    return getWithToken('/studiogroups', params, { cache: false })
  },
  postStudioGroup: async (params = {}) => {
    return postWithToken('/studiogroups', params)
  },
  putStudioGroup: async ({ id, params = {} }) => {
    return putWithToken(`/studiogroups/${id}`, params)
  },
  deleteStudioGroup: async (id) => {
    return deleteWithToken(`/studiogroups/${id}`)
  },
  getStudioGroupById: async (id, params = {}) => {
    return getWithToken(`/studiogroups/${id}`, params, { cache: false })
  },
  getStudioGroupMboIntegrations: async (id, params = {}) => {
    return getWithToken(`/studiogroups/${id}/mbo-integrations`, params, { cache: false })
  },
  testStudioGroupMboIntegrations: async (id, params = {}) => {
    return getWithToken(`/studiogroups/${id}/mbo-integrations/test`, params, { cache: false })
  },
  getStudioGroupStudios: async ({ id, page }, params = {}) => {
    return getWithToken(`/studiogroups/${id}/studios?page=${page}`, params, { cache: false })
  },

  /**
   * Mind Body Site
   */
  postMindbodySite: async (id, params = {}) => {
    return postWithToken(`/studiogroups/${id}/assign-mbo-site`, params)
  },

  putMindbodySite: async (id, params = {}) => {
    const { mbo_sync_site_id } = params
    return putWithToken(`/studiogroups/${id}/mbo-integrations/${mbo_sync_site_id}`, params)
  },

  //! Note, that site id is the FitOn Health site id associated with the mbsite;
  //! not the mb_id
  getSyncMBSiteByIdAndStudioGroupId: async (params = {}) => {
    const { id, siteid } = params
    //console.log('params', params)
    return getWithToken(`/sync/mbo/studio_groups/${id}/sites/${siteid}`)
  },

  /**
   * Stripe
   */
  getStripeSubscriptions: async (params = {}) => {
    try {
      return await getWithToken('/stripe/subscriptions', params)
    } catch (err) {
      console.log('Could not get Stripe Subscriptions')
    }
  },
  getStripeCards: async (params = {}) => {
    return await getWithTokenNoStore('/stripe/cards', params)
  },
  getFlexCards: async ({ user_id } = {}) => {
    return await getWithTokenNoStore(`/users/${user_id}/flex-cards`)
  },
  postPaymentMethod: async (userId, paymentMethodId) => {
    return await postWithToken(`/stripe/users/${userId}/payment-methods/create`, { source: paymentMethodId })
  },
  deletePaymentMethod: async (userId, paymentMethodId) => {
    await deleteWithToken(`/stripe/users/${userId}/payment-methods/${paymentMethodId}/delete`, {
      userId,
      paymentMethodId,
    })
    return await getWithTokenNoStore('/stripe/cards', { user_id: userId })
  },
  deactivateFlexCard: async (userId) => {
    return await postWithToken(`/users/${userId}/deactivate-flex-card`)
  },

  /**
   * Transactions
   */
  getTransactions: async (params = {}) => {
    return getWithToken('/transactions', params, { cache: false })
  },
  getTransactionById: async (id, params = {}) => {
    return getWithToken(`/transactions/${id}`, params, { cache: false })
  },
  postTransactionSupply: async (params = {}) => {
    return postWithToken('/transactions/supplies', params)
  },
  postTransactionAdjustment: async (transaction_id, params = {}) => {
    return postWithToken(`/transactions/${transaction_id}/adjustments`, params)
  },

  /**
   * Users
   */
  getUsers: async (params = {}) => {
    return getWithToken('/users', params, { cache: false })
  },
  getUserById: async (id, params = {}) => {
    return getWithToken(`/users/${id}`, params, { cache: false })
  },
  putUserById: async (id, data = {}) => {
    return putWithToken(`/users/${id}`, data)
  },
  resetUserPassword: async (params = {}) => {
    return postWithToken(`/users/password-reset`, params)
  },
  enableUploaderRole: async ({ userId, enabled = true } = {}) => {
    return putWithToken(`/users/${userId}/uploader-role`, { userId, enabled })
  },
  getUserMemberships: async (user_id, params = {}) => {
    return getWithToken(`/users/${user_id}/memberships`, params, { cache: false })
  },
  postUserMembership: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/memberships`, params)
  },
  //cancel user membership updates the endate to today's date.
  //it's not a real delete
  cancelUserMembership: async (user_id, membership_id) => {
    //console.log(`Admin api, user id: ${user_id}, membership id: ${membership_id}`)
    return deleteWithToken(`/users/${user_id}/memberships/${membership_id}`)
  },
  reactivateUserMembership: async (params = {}) => {
    return postWithToken(`users/memberships/reactivate`, params)
  },
  postUserAddress: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/addresses`, params)
  },
  postUserFitKit: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/fitkit`, params)
  },
  postUserPurchase: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/purchase`, params)
  },
  postUserReservation: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/reservations`, params)
  },
  postUserClassReservation: async (user_id, params = {}) => {
    return postWithToken(`/users/${user_id}/class-reservations`, params)
  },
  getUserSponsors: async (user_id, params = {}) => {
    return getWithToken(`/users/${user_id}/sponsors`, params)
  },
  postUserEmail: async (user_id, email, newEmail) => {
    const data = {
      email,
      newEmail,
    }
    return postWithToken(`/users/${user_id}/email`, data)
  },
  postUserEmailB2c: async (user_id, email, newEmail) => {
    const data = {
      email,
      newEmail,
    }
    return postWithToken(`/sync/b2c/signin-name`, data)
  },

  /**
   * Challenges
   */
  getChallenges: (params = {}) => {
    return getWithToken('/challenges', params, { cache: false })
  },
  postChallenge: async (params = {}) => {
    return postWithToken(`/challenges`, params)
  },

  /**
   * Subscriptions
   */
  getSubscriptions: (params = {}) => {
    return getWithToken('/subscriptions', params, { cache: false })
  },
  deleteSubscription: (id) => {
    return deleteWithToken(`/subscriptions/${id}/cancel`)
  },

  /**
   * Reservations
   */
  getReservations: (params = {}) => {
    return getWithToken('/reservations', params, { cache: false })
  },
  getReservationsById: (id, params = {}) => {
    return getWithToken(`/reservations/${id}`, params, { cache: false })
  },
  postResendReservation: async (reservation_id) => {
    return postWithToken(`/reservations/${reservation_id}/resendemail`)
  },
  deleteReservation: (id) => {
    return deleteWithToken(`/reservations/${id}`)
  },

  /**
   * User Activities
   */
  getUserActivities: (params = {}) => {
    return getWithToken('/fiton/events', params, { cache: false })
  },

  postUserActivity: async (params) => {
    return postWithToken(`/fiton/events`, params)
  },

  /**
   * Promotions
   */
  postPromotions: async (params = {}) => {
    return postWithToken('/promocodes/promo-codes', params)
  },
  getPromotions: async (params = {}) => {
    return getWithToken('/promocodes', params, { cache: false })
  },
  getPromotionById: (id, params = {}) => {
    return getWithToken(`/promocodes/${id}`, params, { cache: false })
  },
  putPromotionById: async (id, data = {}) => {
    return putWithToken(`/promocodes/${id}`, data)
  },
  deletePromotion: async (id) => {
    return deleteWithToken(`/promocodes/${id}`)
  },
  getPromotionBenefit: (id, params = {}) => {
    return getWithToken(`/promocodes/${id}/benefit`, params, { cache: false })
  },
  putPromotionBenefit: (id, params = {}) => {
    return putWithToken(`/promocodes/${id}/benefit`, params)
  },
  getPromotionAuthorizedUsers: (id, params = {}) => {
    return getWithToken(`/promocodes/${id}/authorized-users`, params, { cache: false })
  },

  /**
   * Budgets
   */
  getBudgets: (params = {}) => {
    return getWithToken('/promocodes/budgets', params, { cache: false })
  },
  getBudgetById: (id, params = {}) => {
    return getWithToken(`/promocodes/budgets/${id}`, params, { cache: false })
  },
  putBudgetById: async (id, params = {}) => {
    return putWithToken(`/promocodes/budgets/${id}`, params)
  },
  postBudget: async (params = {}) => {
    return postWithToken('/promocodes/budgets', params)
  },

  /**
   * Consumer
   */
  getConsumerPlans: async (params = {}) => {
    return getWithToken('/consumer/plans', params)
  },

  /**
   * Store Actions
   */
  getStoreItems: async (params = {}) => {
    return getWithToken('/store/items', { is_displayed: true, ...params }, { cache: false })
  },
  getAllStoreItems: async (params = {}) => {
    return getWithToken('/store/items', params, { cache: false })
  },
  getStoreItemById: (id, params = {}) => {
    return getWithToken(`/storeitems/${id}`, params, { cache: false })
  },
  postStoreItem: (params = {}) => {
    return postWithToken('/storeitems', params)
  },
  putStoreItemById: (params = {}) => {
    return putWithToken(`/storeitems`, params)
  },
  deleteStoreItemById: (id) => {
    return deleteWithToken(`/storeitems/${id}`)
  },

  /**
   * Enums
   */
  getTransactionTypes: async (params = {}) => {
    return getWithToken('/transactiontypes', params)
  },
  getReservationTypes: async (params = {}) => {
    return getWithToken('/reservations/types', params)
  },
  getSponsorTypes: async (params = {}) => {
    return getWithToken('/sponsors/types', params)
  },
  getAddressTypes: async (params = {}) => {
    return getWithToken('/users/address/types', params)
  },
  getFitKitTypes: async (params = {}) => {
    return getWithToken('/users/fitkit/types', params)
  },
  getSupplyTransactionTypes: async (params = {}) => {
    return getWithToken('/transactiontypes', { subtype: 1, ...params })
  },
  getAdjustmentTransactionTypes: async (params = {}) => {
    return getWithToken('/transactiontypes', { subtype: 3, ...params })
  },
  getReservationStatusesTypes: async (params = {}) => {
    return getWithToken('/reservations/statuses', params)
  },
  getTags: async (params = {}) => {
    return getWithToken('/tags', params)
  },
  getAmmentities: async (params = {}) => {
    return getWithToken('/tags/amenities', params)
  },
  getStudioEventActionTypes: async (params = {}) => {
    return getWithToken('/studios/eventactions', params)
  },
  getChallengeTypes: async (params = {}) => {
    return getWithToken('/challenges/challenge-types', params)
  },
  unlinkFiton: async (params = {}) => {
    return postWithToken(`/fiton/unlink-account`, params)
  },
  linkFiton: async (params = {}) => {
    return postWithToken(`/fiton/link-account`, params)
  },
}
