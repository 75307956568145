import DateFnsUtils from '@date-io/date-fns'
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import useSettings from 'hooks/useSettings'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import Routes from 'Routes'
import store from 'store'
//stripe imports
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
//stripe imports

import { createMuiTheme } from 'theme'

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  console.warn = () => {}
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const App = () => {
  // const handleLogout = async () => {
  //   await logOut()
  // }

  const { settings } = useSettings()

  const theme = createMuiTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  })

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StylesProvider jss={jss}>
              <SnackbarProvider
                dense
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <HelmetProvider>
                  <Routes />
                </HelmetProvider>
              </SnackbarProvider>
            </StylesProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </Elements>
  )
}

export default App
