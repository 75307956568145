import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { fade, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 999,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    lineHeight: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'capitalize',
    marginLeft: '8px',
  },

  error: {
    color: '#F04438',
    backgroundColor: '#FFD6D2',
  },
  success: {
    color: '#219653',
    backgroundColor: '#E6FDBF',
  },
  warning: {
    color: '#C86107',
    backgroundColor: '#FFE8B2',
  },
}))

const Chip = ({ className = '', color = 'secondary', children, style, label, ...rest }) => {
  const classes = useStyles()

  return (
    <span
      className={clsx(
        classes.root,
        {
          [classes[color]]: color,
        },
        className
      )}
      {...rest}
    >
      {label}
    </span>
  )
}

Chip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(['error', 'warning', 'success']),
}

export default Chip
