import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Fab, Typography, makeStyles } from '@material-ui/core'
import { ArrowLeft as BackIcon } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  backButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}))

const PageHeader = ({ className, title, variant, renderLead, renderChip, renderActions, showBackButton, ...props }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box mb={3}>
      <Grid className={clsx(classes.root, className)} container justifyContent="space-between" spacing={3} {...props}>
        <Grid item>
          <Box display="flex" alignItems="center">
            {showBackButton && (
              <Box mr={2}>
                <Fab className={classes.backButton} size="small" onClick={() => history.goBack()}>
                  <BackIcon />
                </Fab>
              </Box>
            )}
            {renderLead && <Box mr={2}>{renderLead()}</Box>}
            <Typography variant={variant} color="textPrimary">
              {title}
            </Typography>
            {renderChip}
          </Box>
        </Grid>

        <Grid item>{renderActions && renderActions()}</Grid>
      </Grid>
    </Box>
  )
}

PageHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  renderActions: PropTypes.func,
  showBackButton: PropTypes.bool,
}

PageHeader.defaultProps = {
  variant: 'h2',
  showBackButton: false,
}

export default PageHeader
